import React, { useContext } from 'react'
import { /*JournalText,*/ List } from 'react-bootstrap-icons'
import { Button, Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { AuthContext } from '../contexts/AuthContext'
import Logo from '../logo2.png'

const Header = () => {
  const {isAuthenticated, isAuthPending, /*user, userInfo,*/ handleLogin, handleLogout /*, handleSignup*/} = useContext(AuthContext)

  //console.log(isAuthenticated, isAuthPending, user, userInfo)

  return (
    <header className="d-flex">
      <div className="logo p-2">
          {/* <Link to={'/'}><JournalText size='100%' /></Link> */}
          <Link to={'/'}><img src={Logo} alt="Srcpin" style={{width: '100%'}} /></Link>
      </div>
      <div className="nav-wrapper">
        <nav className="d-flex flex-fill flex-wrap">
          <div className="title">
            <h1>Srcpin</h1>
          </div>
          <div className="links">
            {isAuthenticated ? 
              <div>
                <Link to={'/create'}>Create Post</Link>
                {/* <Link to={'/posts'}>My Posts</Link> */}
                {/* {!isAuthPending && user && user.attributes && user.attributes.given_name && (
                  <span className="d-none d-md-inline">Welcome back { user.attributes.given_name }!</span>
                )} */}

                {!isAuthPending
                  ? <Button variant="outline-warning" onClick={handleLogout}>Logout</Button>
                  : <Button variant="outline-warning" disabled>Logging out</Button>
                }
              </div>
            :
              <div className="p-2">

                {!isAuthPending && (
                  <Button variant="outline-warning" onClick={handleLogin}>Login</Button>
                )}
              </div>
            }
          </div>
        </nav>
      </div>
      <div className="burger-wrapper">
        <nav className="d-flex flex-fill flex-wrap align-items-center">
          <div className="title">
            <h1 className="mb-0 mb-md-1 mb-lg-4 pb-0 pb-md-1 pb-lg-4">Srcpin</h1>
          </div>
          <div className="">
            {isAuthenticated ? (
              <Dropdown>
                <Dropdown.Toggle variant="outline-warning" id="burger-menu-dropdown">
                  <List size="30" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item as={Link} to="/create">Create Post</Dropdown.Item>
                  {/* <Dropdown.Item as={Link} to="/posts">My Posts</Dropdown.Item> */}
                  <Dropdown.Divider />
                  {!isAuthPending
                    ? <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                    : <Dropdown.Item disabled>Logging out</Dropdown.Item>
                  }
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <Button variant="outline-warning" onClick={handleLogin}>Login</Button>
            )}
          </div>
        </nav>
      </div>
    </header>
  )
}

export default Header