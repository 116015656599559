import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom';
import Amplify, { Auth } from 'aws-amplify'
import config from './aws-exports'
import ReactTooltip from "react-tooltip";
import Header from './components/Header'
import ProtectedRoute from './components/ProtectedRoute'
import EditorContextProvider from './contexts/EditorContext';
import Home from './components/Home'
import Error from './components/Error'
import Login from './components/Login';
import ViewPost from './components/render/ViewPost';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import useGoogleAnalytics from './hooks/UseGoogleAnalytics'
import { Wait } from './components/Wait';

const Create = React.lazy(() =>
  import('./components/create/Create')
)

const Preview = React.lazy(() =>
  import('./components/render/Preview')
)

const Created = React.lazy(() =>
  import('./components/create/Created')
)

const getAbsoluteDomainUrl = () => {
  if (window
      && "location" in window
      && "protocol" in window.location
      && "host" in window.location) {
      return window.location.protocol + "//" + window.location.host;
  }
  return null;
}

const Routes = () => {

  useGoogleAnalytics()

  return (
    <Switch>
      <Route path="/" exact >
        <Home />
      </Route>
      <Route path="/login" >
        <Login />
      </Route>
      <ProtectedRoute path="/create">
        <React.Suspense fallback={<Wait />}>
          <Create />
        </React.Suspense>
      </ProtectedRoute>
      <ProtectedRoute path="/preview">
        <React.Suspense fallback={<Wait />}>
          <GoogleReCaptchaProvider
            reCaptchaKey="6LfQWUUdAAAAABi8IhS-_fXhfhKw-gC6TfKH9geh"
            language="en&badge=bottomleft" //this is a hack since the tool currently has no ability to set badge position
            scriptProps={{
              async: true, // optional, default to false,
              defer: false, // optional, default to false
              appendTo: 'head', // optional, default to "head", can be "head" or "body",
              nonce: undefined // optional, default undefined
            }}
          >
            <Preview />
          </GoogleReCaptchaProvider>
        </React.Suspense>
      </ProtectedRoute>
      <ProtectedRoute path="/created">
        <React.Suspense fallback={<Wait />}>
          <Created />
        </React.Suspense>
      </ProtectedRoute>
      <Route path="/:slug">
        <ViewPost />
      </Route>
      <Route component={Error} />
    </Switch>
  )
}

const App = () => {

  const url = getAbsoluteDomainUrl()
  config.oauth = {
    domain: "srcpin.auth.us-east-1.amazoncognito.com",
    scope: [
      "phone",
      "email",
      "profile",
      "openid",
      "aws.cognito.signin.user.admin"
    ],
    redirectSignIn: url,
    redirectSignOut: url,
    responseType: "code"
  }

  //console.log('url', url)

  //if (url.includes("//localhost:")) {
  //if (url.includes("://www.srcpin.com")) {
  if (url.includes("srcpin.com")) {
    //config.aws_user_pools_web_client_id = '6bipvhcpctfd9dgkkdvi16agq8' //joe's dev AWS localhost
    //config.aws_user_pools_web_client_id = '6qu31jjdnoampdh4kj1vtr1ors' //joe's staging AWS localhost
    config.aws_user_pools_web_client_id = '1pdnpa66msaklpa0gb0d5h44ol' //srcpin production
  }
  // else if (url.includes("://srcpin.com")) {
  //   config.aws_user_pools_web_client_id = '7ruvrb8tki0dhc6mpn933bjs03' //srcpin production without www
  // }
  else {
    //config.aws_user_pools_web_client_id = '2tnr1sfhjug567ac178pul8ojg' //joe's dev AWS web addy
    //config.aws_user_pools_web_client_id = 'ik52s6pffthmdfo4n5aos997k' //joe's staging AWS web addy
    config.aws_user_pools_web_client_id = '2cnondea6hblivqk5ancmga54i' //srcpin staging
  }

  //console.log(config)

  Amplify.configure(config)
  Auth.configure(config)

  return (
    <main>
      <EditorContextProvider>
        <ReactTooltip multiline delayShow={350} />
        <Header />
        <Routes />
      </EditorContextProvider>
    </main>
  );
}

export default withRouter(App);