import React from 'react'
import { motion } from 'framer-motion'
import Background from '../document.jpg'
import { withRouter } from 'react-router-dom'

const Home = (props) => {

  const handleStartClick = () => {
    props.history.push('/create');
  }

  return (
    <div className="home-container" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.8)), url(${Background})`}}>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-10 col-lg-8 p-sm-2 p-md-5">
            <motion.h1 initial={{ opacity: 0, scale: 0 }} animate={{ scale: 1, opacity: 1 }}>
              Effortlessly link supporting sources to online content
            </motion.h1>
            <motion.p initial={{ opacity: 0, scale: 0 }} animate={{ scale: 1, opacity: 1 }} className="home-container-p">
              Every day, billions of users post, comment, or express opinions on the internet. However, the vast majority of posted content is not fact checked for accuracy nor cited for review. This has nurtured a rapid rise of online misinformation. 
            </motion.p>
            <motion.p initial={{ opacity: 0, scale: 0 }} animate={{ scale: 1, opacity: 1 }} className="home-container-p">
              Srcpin is meant to easily substantiate posts with supporting content, leaving a paper trail of evidence that forms the content’s claim. Everything is packaged into a short, random url that can be shared as a link. Click below to give Srcpin a try!
            </motion.p>
          </div>
        </div>
        <div className="row col-xs-12 col-md-10 col-lg-8">
          <div className="col p-sm-2 pe-sm-2 p-md-5 pe-md-5 pt-0 d-flex justify-content-center justify-content-md-end">
            {/* <Button variant="warning">Get Started! Create a Post...</Button> */}
            <div className="button-start" onClick={handleStartClick}>
              <p>Get started</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(Home)