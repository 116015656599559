import ReactGA from "react-ga4"
 
const TRACKING_ID = "G-1NXD7PYTP3"
 
function init() {
  // Enable debug mode on the local development environment
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development"
  ReactGA.initialize(TRACKING_ID, { debug: isDev })
}
 
function sendEvent(name, params) {
  ReactGA.event(name, params)
}
 
function sendPageview(path, user) {
  if (user && user.attributes && user.attributes.sub) {
    ReactGA.send({ hitType: "pageview", page: path, userId: user.attributes.sub })    
  } else {
    ReactGA.send({ hitType: "pageview", page: path })
  }
}

const analytics = {
  init,
  sendEvent,
  sendPageview,
}

export default analytics