import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { API } from 'aws-amplify'
import Post from './Post'
import PostHeader from './PostHeader'
import Corroboration from './Corroboration';
import ReactTooltip from 'react-tooltip';
import { Wait } from '../Wait';
import useTitle from '../../hooks/UseTitle';
import analytics from "../../analytics"
// import SectionHeader from './SectionHeader'

const ViewPost = () => {

  const [postData, setPostData] = useState(null)

  const loadPost = (postId) => {
    const options = {
      body: {
        postId
      }
    }

    //return API.post('reactrestauthapi', '/posts', options) //joe dev AWS
    return API.post('srcpinApi', '/posts', options)
    .then(data => {
      console.log('data loaded')
      //return JSON.parse(data)
      return data
    }).catch(res => {
      console.log(res)
    });
  }

  const { slug } = useParams()
  useTitle(`Srcpin - View`)
  
  // console.log(props)
  console.log(slug)
  // console.log(useParams())

  useEffect(() => {

    async function fetchPost() {
      let response = await loadPost(slug)
      console.log(response)
      setPostData(response)
      ReactTooltip.rebuild()
    }

    fetchPost()

    analytics.init()
    analytics.sendEvent("view_post", {
      post_id: slug
    })

  }, [slug, setPostData])

  
  if (postData && postData.body && postData.body.templateVersion === 1)
  {
    const corroborationsContent = postData.body.corroborations.map((corroboration) => {
      return <Corroboration postId={slug} corroboration={corroboration} key={corroboration.key} />;
    })

    return (
      <div>
        <PostHeader lastModified={postData.lastModified} />
        <Post post={postData.body.html} />
        
        {/* <SectionHeader title="Sources" /> */}
        <div className="corroborations">
          { corroborationsContent }
        </div>
      </div>
    )
  }
  else {
    return (
      <Wait />
    )
  }
}

export default ViewPost
