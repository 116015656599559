import React from 'react'
import ReactTimeago from 'react-timeago'
//import SectionHeader from './SectionHeader'

const Post = ({lastModified, ...props}) => {

  const lastModifiedDate = new Date(lastModified)

  return (
    <div className="post-header">
      {/* <SectionHeader title="My Post" /> */}
      <div className="post-header-content">
        <div style={{display: 'inline-block', marginLeft: '0.3em'}} data-tip={`${lastModifiedDate.toLocaleDateString()} ${lastModifiedDate.toLocaleTimeString()}`}>
          Created <ReactTimeago date={lastModified} title="" />
        </div>
      </div>
    </div>
  )
}

export default Post