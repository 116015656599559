import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AuthContext } from '../contexts/AuthContext'
import Login from './Login'

const ProtectedRoute = ({component: Component, children, ...rest}) => {
  const {isAuthenticated, isAuthPending /*, user*/} = useContext(AuthContext)
  
  // console.log('Component', Component)
  // console.log('children', children)
  // console.log('rest', rest)
  // console.log('isAuthenticated', isAuthenticated)
  // console.log('isAuthPending', isAuthPending)

  if (isAuthPending) {
      <div className="d-flex align-items-center justify-content-center" style={{ position: 'absolute', top: '50%', left: '50%',  transform: 'translate(-50%, -50%)' }}>
        <div className="spinner-grow text-light" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow text-light" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow text-light" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
  }
  else if (!isAuthenticated) {
    return (
      <Login />
    )
  }

  return (
    <Route {...rest}
      render = {(props) => {
        if (isAuthenticated /*&& !isAuthPending*/) {
          if (Component) {
            return <Component {...props} />
          } else if (children) {
            return <React.Fragment>{children}</React.Fragment>
          } else {
            return <Redirect to={{path:"/", state: props.location}} />
          }
        }
      }}
    />
  )
}

export default ProtectedRoute