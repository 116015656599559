import React from 'react'

export const Wait = () => {
  return (
    <div className="d-flex align-items-center justify-content-center" style={{ position: 'absolute', top: '50%', left: '50%',  transform: 'translate(-50%, -50%)' }}>
    <div className="spinner-grow text-light" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
    <div className="spinner-grow text-light" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
    <div className="spinner-grow text-light" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
  )
}
