import React, { createContext, useRef, useState } from 'react'

export const EditorContext = createContext()

const EditorContextProvider = (props) => {
  const [showCorroborations, setShowCorroborations] = useState(false)
  const [currentCorroboration, setCurrentCorroboration] = useState(null)
  const [corroborations, setCorroborations] = useState([]);
  const [unlinkedCorroborationsIndex, setUnlinkedCorroborationsIndex] = useState(0)
  const [postEditorState, setPostEditorState] = useState(null)
  const postEditRef = useRef(React.createRef());
  const corroborationEditRef = useRef(React.createRef());

  const resetContext = () => {
    setPostEditorState(null)
    setShowCorroborations(false)
    setCorroborations([])
    setCurrentCorroboration(null)
    setUnlinkedCorroborationsIndex(0)
  }

  return (
    <EditorContext.Provider value={{
      showCorroborations,
      currentCorroboration,
      corroborations,
      unlinkedCorroborationsIndex,
      postEditorState,
      setShowCorroborations,
      setCurrentCorroboration,
      setCorroborations,
      setUnlinkedCorroborationsIndex,
      setPostEditorState,
      postEditRef,
      corroborationEditRef,
      resetContext
    }}>
      { props.children }
    </EditorContext.Provider>
  )
}

export default EditorContextProvider