import React, { useEffect, useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { withRouter } from 'react-router-dom'
import { Wait } from './Wait'

const Login = ({history}) => {

  const { isAuthenticated, isAuthPending, handleLogin } = useContext(AuthContext)

  // console.log('Login isAuthenticated', isAuthenticated)
  // console.log('Login isAuthPending', isAuthPending)

  useEffect(() => {
    if (!isAuthenticated /*&& !isAuthPending*/) {
      var timer = setTimeout(() => {
        handleLogin()
      }, 1500)

      const unlisten = history.listen((location, action) => { clearTimeout(timer) });

      return unlisten

      //return () => clearTimeout(timer)
    } else {
      history.push('/')
    }
  }, [isAuthenticated, isAuthPending, handleLogin, history])

  return (
    <Wait />
  )
}

export default withRouter(Login)
