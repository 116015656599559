import React from 'react'
import DOMPurify from 'dompurify';
//import SectionHeader from './SectionHeader'

const Post = ({post, ...props}) => {

  let postContent = DOMPurify.sanitize(post)
  //this is done in a strange way to allow the string to pass santization
  //before we replace the placeholders with onclick and id attributes.
  postContent = postContent.replaceAll('href="', 'onClick="scrollToView(\'').replaceAll('-href-end-', '\')" id="').replaceAll('-id-end', '"')

  return (
    <div className="post">
      {/* <SectionHeader title="My Post" /> */}
      <div className="post-content">
        { <div dangerouslySetInnerHTML={{ __html: postContent }} /> }
      </div>
    </div>
  )
}

export default Post