import React, { useState, useEffect, /*useState, useRef*/ } from 'react'
import { Auth, Hub } from 'aws-amplify'

const getAbsoluteDomainUrl = () => {
  if (window
      && "location" in window
      && "protocol" in window.location
      && "host" in window.location) {
      return window.location.protocol + "//" + window.location.host;
  }
  return null;
}

const handleLogin = async () => {
  try
  {
    await Auth.federatedSignIn()
  } catch (error) {
    console.log('error signing out: ', error);
  }
}

//   const handleSignup = async () => {
//     try
//     {
//       await Auth.signUp()
//     } catch (error) {
//       console.log('error signing up: ', error);
//     }
//   }

const handleLogout = async () => {
  try {
    return Auth.signOut()
    //alert('Logged out')
  } catch (error) {
    console.log('error signing out: ', error);
  }
}

// export const AuthContext = React.createContext({
//   isAuthenticated: false,
//   isAuthPending: true,
//   user: null,
//   username: "",
//   idToken: "",
//   appUrl: getAbsoluteDomainUrl(),
//   handleLogin,
//   handleLogout
// });

// const AuthListener = async (data, setAuthData) => {
//   //const authData = data.payload.data;

//   await Auth.currentAuthenticatedUser().then((user) => {
//     console.log(user)
//     const authData = user

//     switch (data.payload.event) {
//       case "signIn":
//         const signInSessionData = authData.signInUserSession;
//         console.log('signin')
//         console.log(authData)
  
//         setAuthData({
//           isAuthenticated: true,
//           isAuthPending: false,
//           username: authData.attributes.email,
//           user: authData,
//           idToken: signInSessionData.idToken.jwtToken,
//           appUrl: getAbsoluteDomainUrl(),
//           handleLogin,
//           handleLogout
//         });
//         break;
//       case "signUp":
//         const signUpSessionData = authData.signUpUserSession;
  
//         setAuthData({
//           isAuthenticated: true,
//           isAuthPending: false,
//           username: authData.attributes.email,
//           user: authData,
//           idToken: signUpSessionData.idToken.jwtToken,
//           appUrl: getAbsoluteDomainUrl(),
//           handleLogin,
//           handleLogout
//         });
//         break;
//       case "signOut":
//         setAuthData({
//           isAuthenticated: false,
//           isAuthPending: false,
//           username: "",
//           user: null,
//           idToken: "",
//           appUrl: getAbsoluteDomainUrl(),
//           handleLogin,
//           handleLogout
//         });
//         break;
//       case "signIn_failure":
//         console.log("user sign in failed");
//         break;
//       default:
//         console.log("event default case: " + data.payload.event);
//         break;
//     }
//   })
// };

// export const AuthContextProvider = props => {
//   const [authData, setAuthData] = React.useState({
//     isAuthenticated: false,
//     isAuthPending: true,
//     username: "",
//     user: null,
//     idToken: "",
//     appUrl: getAbsoluteDomainUrl(),
//     handleLogin,
//     handleLogout
//   });

//   React.useEffect(() => {

//     Hub.listen("auth", data => AuthListener(data, setAuthData));

//     const getCurrentUserData = async () => {
//       await Auth.currentAuthenticatedUser()
//         .then(async data => {
//           console.log(data)
//           const signInSessionData = await Auth.currentSession();
//           console.log(signInSessionData)

//           return setAuthData({
//             isAuthenticated: true,
//             isAuthPending: false,
//             username: data.attributes.email,
//             user: data,
//             idToken: signInSessionData.idToken.jwtToken,
//             appUrl: getAbsoluteDomainUrl(),
//             handleLogin,
//             handleLogout
//           });
//         })
//         .catch(err => {
//           return setAuthData({
//             isAuthenticated: false,
//             isAuthPending: false,
//             username: "",
//             user: null,
//             idToken: "",
//             appUrl: getAbsoluteDomainUrl(),
//             handleLogin,
//             handleLogout
//           });
//         });
//     };

//     getCurrentUserData();
//   }, []);

//   return <AuthContext.Provider value={authData} {...props} />;
// };

export const AuthContext = React.createContext({
  isAuthenticated: false,
  isAuthPending: true,
  user: null,
  userInfo: null,
  appUrl: getAbsoluteDomainUrl(),
  handleLogin,
  handleLogout
});

const AuthContextProvider = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isAuthPending, setIsAuthPending] = useState(true)
  const [user, setUser] = useState(null)
  const [userInfo, setUserInfo] = useState(null)
  const [appUrl, setAppUrl] = useState(null)

  const getUser = () => {
    return Auth.currentAuthenticatedUser()
      .then(userData => userData)
      .catch(() => console.log('Not signed in'));
  }

  // const getUserInfo = () => {
  //   return Auth.currentUserInfo()
  //     .then(userInfoData => userInfoData)
  //     .catch(() => console.log('Not signed in'));
  // }

  // const getToken = async () => {
  //   return Auth.currentSession()
  //     .then(session => session)
  //     .catch(err => console.log(err));
  // }

  const setAuthData = (user) => {
    console.log('setAuthData', user)
    if (user) {
      setIsAuthenticated(true)
      setUser(user)
      //getUserInfo().then(userInfo => setUserInfo(userInfo))
    } else {
      setIsAuthenticated(false)
      setUser(null)
      setUserInfo(null)
      //setToken(null)
    }

    setIsAuthPending(false)
  }

  // const setTokenData = (token) => {
  //   console.log('setTokenData', token)
  //   if (token) {
  //     setToken(token.idToken.jwtToken)
  //   } else {
  //     setToken(null)
  //   }
  // }

  // const handleLogin = async () => {
  //   try
  //   {
  //     await Auth.federatedSignIn()
  //   } catch (error) {
  //     console.log('error signing out: ', error);
  //   }
  // }

  // const handleSignup = async () => {
  //   try
  //   {
  //     await Auth.signUp()
  //   } catch (error) {
  //     console.log('error signing up: ', error);
  //   }
  // }

  // const handleLogout = async () => {
  //   try {
  //     await Auth.signOut()  
  //     //alert('Logged out')
  //   } catch (error) {
  //     console.log('error signing out: ', error);
  //   }
  // }

  // const getAbsoluteDomainUrl = () => {
  //   if (window
  //       && "location" in window
  //       && "protocol" in window.location
  //       && "host" in window.location) {
  //       return window.location.protocol + "//" + window.location.host;
  //   }
  //   return null;
  // }

  useEffect(() => {
    console.log('Configuring Auth HUB')

    Hub.listen('auth', ({ payload: { event, data } }) => {
      console.log('auth called', event, data)

      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          console.log('sign in', event, data)
          // this.setState({ user: data})
          /*setUser(data)
          setIsAuthenticated(true)
          setIsAuthPending(false)*/

          getUser().then(userData => setAuthData(userData));
          //getToken().then(userToken => setTokenData(userToken));

          break
        case 'tokenRefresh': {
          console.log('token refresh', event, data)
          getUser().then(userData => setAuthData(userData));
          break;
        }
        case 'signUp':
          console.log('sign up')
          break
        case 'signIn_failure':
          console.log('signIn_failure')
          // setUser(null)
          // setIsAuthenticated(false)
          // setIsAuthPending(false)
          //setAuthData(null)
          //this.props.history.push('/')
          break
        case 'signOut':
          console.log('sign out')
          //Auth.signOut()
          // setUser(null)
          // setIsAuthenticated(false)
          // setIsAuthPending(false)
          setAuthData(null)
          this.props.history.push('/')
          break
        // case 'authPending': //custome dispatch
        //   console.log('auth pending')
        //   setIsAuthPending(true)
        //   break
        default:
          console.log('Unknown login action', event, data)
          break;
      }
    }, [])

    setAppUrl(getAbsoluteDomainUrl())
    setIsAuthPending(true)
    getUser().then(userData => setAuthData(userData));
    //getToken().then(userToken => setTokenData(userToken));
  }, [])
  // [someId] Or [] if effect doesn't need props or state

  return <AuthContext.Provider value={{appUrl, isAuthenticated, isAuthPending, user, userInfo, /*token,*/ handleLogin, handleLogout }} {...props} />;
}

export default AuthContextProvider