import React, { useContext } from "react"
import { useLocation } from "react-router-dom"
import { AuthContext } from "../contexts/AuthContext"
import analytics from "../analytics"

const useGoogleAnalytics = () => {
  const location = useLocation()
  const { user } = useContext(AuthContext)
 
  React.useEffect(() => {
    analytics.init()
  }, [])
 
  React.useEffect(() => {
    const currentPath = location.pathname + location.search
    analytics.sendPageview(currentPath, user)

    console.log('pave view in ga', currentPath)

  }, [location, user])
}

export default useGoogleAnalytics