import React from 'react'
import DOMPurify from 'dompurify';

const Corroboration = ({corroboration, isPreview = false, postId = null, ...props}) => {
  const corroborationKey = DOMPurify.sanitize(corroboration.key)
  let corroborationText = DOMPurify.sanitize(corroboration.text)

  if (!corroboration.isLinked) {
    var unlinkedVerbage = '(unlinked)'
    var unlinkedCorroborationText = 'Unlinked Source-'
    if (corroborationText.endsWith(unlinkedVerbage)) {
      corroborationText = corroborationText.slice(0, corroborationText.length - unlinkedVerbage.length)
    }
    else if (corroborationText.startsWith(unlinkedCorroborationText)) {
      corroborationText = ''
    }
  }
  //const rex = /<img[^>]+src="(blob:(http|https):\/\/[^">]+)"/g;

  const TEMPORARY_ATTRIBUTE = 'data-temp-href-target'

  DOMPurify.addHook('beforeSanitizeAttributes', function (node) {
      if (node.tagName === 'A') {
          if (!node.hasAttribute('target')) {
              node.setAttribute('target', '_self')
          }
  
          if (node.hasAttribute('target')) {
              node.setAttribute(TEMPORARY_ATTRIBUTE, node.getAttribute('target'))
          }
      }
  })
  
  DOMPurify.addHook('afterSanitizeAttributes', function (node) {
      if (node.tagName === 'A' && node.hasAttribute(TEMPORARY_ATTRIBUTE)) {
          node.setAttribute('target', node.getAttribute(TEMPORARY_ATTRIBUTE))
          node.removeAttribute(TEMPORARY_ATTRIBUTE)
          // if (node.getAttribute('target') === '_blank') {
          //     node.setAttribute('rel', 'noopener')
          // }
      }
  })

  const dirtyHtml = corroboration.html.replaceAll('src="blob:', 'src="')
  let sanitizedHtml = DOMPurify.sanitize(dirtyHtml)
  if (corroboration.imgSrcs && corroboration.imgSrcs.length > 0) {
    corroboration.imgSrcs.forEach((imgSrc) => {
      if (!isPreview) {
        console.log(imgSrc.blob.slice(5))
        console.log(imgSrc)
        sanitizedHtml = sanitizedHtml.replace(imgSrc.blob.slice(5), imgSrc.blob)
        console.log(sanitizedHtml)
        sanitizedHtml = sanitizedHtml.replace(imgSrc.blob, `https://srcpin-storage.s3.us-east-1.amazonaws.com/app-data/images/${postId}/${imgSrc.blobid}`)
      } else {
        sanitizedHtml = sanitizedHtml.replace(imgSrc.blob.slice(5), imgSrc.blob.slice(0, imgSrc.blob.indexOf("?")))
      }
    })
  }

  //console.log('corroboration.isLinked: ' + corroboration.isLinked)

  return (
		<div className="corroboration" id={`ref-${corroboration.key}`}>
			<div className="corroboration-content">
        {corroborationText !== '' && (
          <div className="corroboration-header">
            <div className={`corroboration-header-link ${(corroboration.isLinked ? '' : 'unlinked')}`} dangerouslySetInnerHTML={{ __html: `<div onClick="scrollToView('#${corroborationKey}')"><span>${corroborationText}</span></div>` }} />
          </div>
        )}
        <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
			</div>
		</div>
  )
}

export default Corroboration